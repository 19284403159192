
import { defineComponent, onMounted, ref } from "vue";
import MessageHistory from "@/components/common/modals/MessageHistory.vue";
import { MrUiKitButton } from "mr-ui-kit";
import { useStore } from "vuex";
import { HistoryMessage } from "@/interfaces";
import RequestService from "@/services/RequestService";

export default defineComponent({
  name: "history",
  components: {
    MessageHistory,
    MrUiKitButton,
  },
  setup() {
    const messages = ref<HistoryMessage[]>([]);
    const store = useStore();
    const showModal = ref(false);
    const isMounted = ref(false);
    const request = store.getters.getRequest;

    const getHistoryMessages = async () => {
      messages.value = await RequestService.getHistory(request.id);
    };

    const toggleModal = async () => {
      await getHistoryMessages();
      showModal.value = !showModal.value;
    };

    onMounted(async () => {
      //   await getHistoryMessages();
      isMounted.value = true;
    });

    return {
      showModal,
      messages,
      isMounted,
      toggleModal,
    };
  },
});
