
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import { MrUiKitForm } from "mr-ui-kit";
import RequestStepper from "@/components/common/RequestStepper.vue";
import { UserRequest, UserRequestStatus } from "@/interfaces";
import { required } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import History from "@/components/common/History.vue";
import { useStore } from "vuex";
import * as _ from "lodash";

export default defineComponent({
  name: "request-back",
  components: {
    RequestStepper,
    MrUiKitForm,
    History,
  },
  props: {
    request: {
      type: Object as PropType<UserRequest> | null,
      default: null,
    },
  },
  setup(props, { emit }: any) {
    const stepper = ref();
    const form = ref();
    const isMounted = ref(false);
    const store = useStore();

    // const statusItems: {
    //   label: string;
    //   value: string;
    // }[] = Object.entries(UserRequestStatus).map(([label, value]) => ({
    //   label,
    //   value,
    // }));

    const userRequestStatus = store.getters.getUserRequestStatus.filter(
      (d: any) => d.value !== UserRequestStatus.Draft
    );

    const manageModel = () => {
      if (_.isNil(props.request)) return null;
      let model = _.cloneDeep(props.request) as any;

      model.history = {
        date: "",
        message: "",
        status: "",
      };

      model.history.status = model.history.status = userRequestStatus.find(
        (d: any) => d.value === model.history.status
      );

      return model;
    };

    const model = reactive<UserRequest>(manageModel() || props.request);

    const formFields = reactive([
      {
        component: "MrUiKitSelect",
        name: "status",
        props: {
          label: "Stato richiesta",
          labelPosition: "top",
          placeholder: "",
          items: userRequestStatus,
        },
        rules: {
          required: helpers.withMessage("Campo Obbligatorio", required),
        },
      },
      {
        component: "MrUiKitTextEditor",
        name: "message",
        props: {
          label: "Ragione Sociale",
          labelPosition: "top",
          toolbarElements: ["header", "list", "fontStyle", "colors"],
        },
      },
    ]);

    const onSubmit = async () => {
      let modelToSend = _.cloneDeep(model) as any;
      stepper.value.sendForm(
        modelToSend,
        modelToSend.history.status.value as any
      );

      model.history!.status = null;
      model.history!.message = null;
      form.value.v$.$reset();
    };

    const showForm = computed(() => {
        return true
    //   return (
    //     store.getters.getRequest.status != UserRequestStatus.Rejected &&
    //     store.getters.getRequest.status != UserRequestStatus.Accepted
    //   );
    });

    onMounted(async () => {
      isMounted.value = true;
    });

    return {
      isMounted,
      model,
      formFields,
      userRequestStatus,
      stepper,
      onSubmit,
      form,
      showForm,
    };
  },
});
