import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "requests-front"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_History = _resolveComponent("History")!
  const _component_RequestStepper = _resolveComponent("RequestStepper")!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Message, {
          history: _ctx.request.history
        }, null, 8, ["history"]),
        (_ctx.request.history)
          ? (_openBlock(), _createBlock(_component_History, {
              key: _ctx.request.history.date
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_RequestStepper, { request: _ctx.request }, null, 8, ["request"])
      ]))
    : _createCommentVNode("", true)
}