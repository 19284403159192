import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "request-page mt-7"
}
const _hoisted_2 = { class: "mb-9 mt-4 d-flex" }
const _hoisted_3 = { class: "fs-title-pages" }
const _hoisted_4 = {
  key: 0,
  class: "fs-3 fw-light text-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitBreadcrumb = _resolveComponent("MrUiKitBreadcrumb")!
  const _component_RequestBack = _resolveComponent("RequestBack")!
  const _component_RequestFront = _resolveComponent("RequestFront")!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MrUiKitBreadcrumb, {
          items: _ctx.items,
          onNavigate: _ctx.onNavigate
        }, null, 8, ["items", "onNavigate"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, [
            _createTextVNode(" Richiesta #" + _toDisplayString(_ctx.request.requestId) + " ", 1),
            (_ctx.isAdmin)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, " di " + _toDisplayString(_ctx.request.user) + " - ", 1))
              : _createCommentVNode("", true),
            (_ctx.user.isAdmin)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(["fs-3 fw-light", [_ctx.getColorType(_ctx.request.history.status).color]])
                }, _toDisplayString(_ctx.getColorType(_ctx.request.history.status).message), 3))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.user.isAdmin)
          ? (_openBlock(), _createBlock(_component_RequestBack, {
              key: 0,
              request: _ctx.request
            }, null, 8, ["request"]))
          : (_openBlock(), _createBlock(_component_RequestFront, {
              key: 1,
              request: _ctx.request
            }, null, 8, ["request"]))
      ]))
    : _createCommentVNode("", true)
}