
import { HistoryMessage, UserRequestStatus } from "@/interfaces";
import RequestService from "@/services/RequestService";
import { defineComponent, onMounted, PropType, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "message-history",
  emits: ["close"],
  props: {
    messages: {
      type: Array as PropType<HistoryMessage[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(props, { emit }: any) {
    const isMounted = ref(false);

    onMounted(async () => {
      isMounted.value = true;
    });

    const onClose = () => {
      emit("close");
    };

    const getColorType = (type: UserRequestStatus) => {
      let color = "";
      let message = "";
      switch (type) {
        case UserRequestStatus.Draft: {
          color = "text-secondary";
          message = "Bozza";
          break;
        }
        case UserRequestStatus.WaitingReview: {
          color = "text-info";
          message = "In attesa di valutazione";
          break;
        }
        case UserRequestStatus.RequestOperation: {
          color = "text-warning";
          message = "Intervento richiesto";
          break;
        }
        case UserRequestStatus.Rejected: {
          color = "text-danger";
          message = "Rifiutata";
          break;
        }
        case UserRequestStatus.Accepted: {
          color = "text-success";
          message = "Approvata";
          break;
        }

        default: {
          color = "text-primary";
          break;
        }
      }
      return { color, message };
    };

    return { isMounted, onClose, getColorType };
  },
});
