
import {
  defineComponent,
  computed,
  ComputedRef,
  onMounted,
  ref,
  watch,
  reactive,
} from "vue";
import RequestFront from "@/components/Frontoffice/RequestFront.vue";
import RequestBack from "@/components/Backoffice/request/RequestBack.vue";
import RequestService from "@/services/RequestService";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { User, UserRequest, UserRequestStatus } from "@/interfaces";
import UserService from "@/services/UserService";
import { MrUiKitBreadcrumb } from "mr-ui-kit";
import { BreadcrumbItem } from "@/interfaces";
import * as _ from "lodash";

export default defineComponent({
  name: "request-page",
  components: {
    RequestFront,
    RequestBack,
    MrUiKitBreadcrumb,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isMounted = ref(false);
    const request = ref<UserRequest | null>(null);

    const reqToggle = store.getters.requestToggle

    const id = ref<any>(null);

    const user: ComputedRef<User> = computed(() => {
      return store.getters.getUser;
    });

    const items = reactive<BreadcrumbItem[]>([
      {
        label: "Storico richieste",
        path: "/requests-list",
      },
      {
        label: "",
        path: "/request-list/",
      },
    ]);

    onMounted(async () => {
      id.value = route.params.id;
      await getSetRequest();
      isMounted.value = true;
    });

    

    watch(reqToggle, () => {
        console.log('watch')
      getSetRequest();
    });

    const getSetRequest = async () => {
      request.value = await RequestService.getRequest(String(id.value));
      store.commit("setRequestFromStep", request.value);
      items[1] = {
        path: store.getters.getRequest.id,
        label: store.getters.getRequest.service ?? store.getters.getRequest.id,
      };
    };

    const getColorType = (type: UserRequestStatus) => {
      let color = "";
      let message = "";
      switch (type) {
        case UserRequestStatus.Draft: {
          color = "text-secondary";
          message = "Bozza";
          break;
        }
        case UserRequestStatus.WaitingReview: {
          color = "text-info";
          message = "In attesa di valutazione";
          break;
        }
        case UserRequestStatus.RequestOperation: {
          color = "text-warning";
          message = "Intervento richiesto";
          break;
        }
        case UserRequestStatus.Rejected: {
          color = "text-danger";
          message = "Rifiutata";
          break;
        }
        case UserRequestStatus.Accepted: {
          color = "text-success";
          message = "Approvata";
          break;
        }
      }
      return { color, message };
    };

    const onNavigate = (item: BreadcrumbItem) => {
      router.push(item.path);
    };

    return {
      user,
      isMounted,
      request,
      getColorType,
      isAdmin: UserService.isAdmin(),
      items,
      onNavigate,
    };
  },
});
