
import { defineComponent, onMounted, ref } from "vue";
import RequestStepper from "@/components/common/RequestStepper.vue";
import History from "@/components/common/History.vue";
import Message from "@/components/UI/Message.vue";

export default defineComponent({
  name: "request-front",
  components: {
    RequestStepper,
    History,
    Message,
  },
  props: {
    request: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const isMounted = ref(false);

    onMounted(async () => {
      isMounted.value = true;
    });

    return { isMounted };
  },
});
