
import { HistoryMessage, UserRequestStatus } from "@/interfaces";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "message",
  props: {
    history: {
      type: Object as PropType<HistoryMessage>,
      default: null,
    },
  },
  setup() {
    const getColorType = (type: UserRequestStatus) => {
      let color = "";
      let message = "";
      let bgColor = "";
      switch (type) {
        case UserRequestStatus.Draft: {
          bgColor = "bg-soft-secondary-custom";
          message = "Bozza";
          break;
        }
        case UserRequestStatus.WaitingReview: {
          bgColor = "bg-soft-info-custom";
          message = "In attesa di valutazione";
          break;
        }
        case UserRequestStatus.RequestOperation: {
          bgColor = "bg-soft-warning-custom";
          message = "Intervento richiesto";
          break;
        }
        case UserRequestStatus.Rejected: {
          bgColor = "bg-soft-danger-custom";
          message = "Rifiutata";
          break;
        }
        case UserRequestStatus.Accepted: {
          bgColor = "bg-soft-success-custom";
          message = "Accettata";
          break;
        }
      }
      return { color, message, bgColor };
    };
    return { getColorType };
  },
});
